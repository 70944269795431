import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {key: 'selected',label: 'Selected'},
    { key: 'invoice_code', label: 'Invoice Code', sortable: true },
    { key: 'payment_status_value', label: 'Status', sortable: true },
    { key: 'user', sortable: true },
    {
      key: 'grand_total', label: 'Total', sortable: true, formatter: val => `${val} BDT`,
    },
    { key: 'invoice_date', label: 'Invoice Date', sortable: true },
    { key: 'last_payment_date', label: 'Last Date', sortable: true },
    { key: 'paid_date', label: 'Date Paid', sortable: true },
    { key: 'payment_method', label: 'Payment Method', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(100)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [100, 300, 500, 1000]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const ispIdFilter = ref(null)
  const is_pagination = ref(true)
  // const unpaid_amount = ref(0)
  // const paid_amount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, ispIdFilter], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store.dispatch('app-invoice/fetchInvoices', {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      payment_status: (statusFilter.value) ? statusFilter.value.value : null,
      is_pagination: 'true',
      isp_id: (ispIdFilter.value) ? ispIdFilter.value.value : null,
    })
      .then(response => {
        //console.log(response.data.data.invoices.data);
        const invoices  = response.data.data.invoices.data;
        const total = response.data.data.total;
        callback(invoices)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }
  const ispId = $('#ispListId option:selected').val()

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    // unpaid_amount,
    statusFilter,
    ispIdFilter,
    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    ispId,
  }
}
